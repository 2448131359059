const envConf = require("./polygon");

export default {
  knn3Endpoint: "https://master.graphql.knn3.xyz/graphql",
  knn3Credentials: "https://credentials.knn3.xyz/api/#/",
  // all nodes are on ethereum mainnet for now
  graphScan: "https://www.etherscan.com",
  graphProvider: "https://palpable-sleek-darkness.quiknode.pro/b4424effb5e8b574182242591fcab33d380f2ff4",
  lensFrensUrl: "https://www.lensfrens.xyz",
  baseURL: "https://track.service.KNN3.xyz",
  ensNftAddress: '0x57f1887a8bf19b14fc0df6fd9b2acc9af147ea85',
  ...envConf,
};
