import React, { useEffect, useState } from "react";
import { Switch } from "antd";
import config from "config";
import { useRecoilState } from "recoil";
import { nodeInfoState, currentHighlightNodeState } from "store/atom";
import { getAddrType, copyToClipboard, shortenAddr } from "lib/tool";
import IconCopy from "assets/icons/copy.png";
import AvatarItem from "components/AvatarItem";
import cn from "classnames";
import style from "../style.module.scss";

export default function Address() {
  const [nodeInfo] = useRecoilState(nodeInfoState);
  const [, setCurrentHighlightNode] = useRecoilState(currentHighlightNodeState);
  const [ensVisible, setEnsVisible] = useState(false);
  const [lensVisible, setLensVisible] = useState(false);
  const [allCollectionsVisible, setAllCollectionsVisible] = useState(false);
  const [addressType, setAddressType] = useState("");

  const hasMoreCollections = nodeInfo && nodeInfo.holdNfts && nodeInfo.holdNfts.length > 8 ? true : false ;

  const checkAddrType = async () => {
    setAddressType(await getAddrType(nodeInfo.address));
  };

  const doHighlight = async (val) => {
    setCurrentHighlightNode(val);
  };

  useEffect(() => {
    if (!nodeInfo.address) {
      return;
    }
    checkAddrType();
  }, [nodeInfo]);

  return (
    <div>
      <div>
        <div className={style.title}>Address</div>
        <div className={style.account}>
          <a
            href={`${config.graphScan}/address/${nodeInfo.address}`}
            target="_blank"
          >
            {shortenAddr(nodeInfo.address, 10)}
          </a>
          <img
            src={IconCopy}
            onClick={() => copyToClipboard(nodeInfo.address)}
            className={style.copyIcon}
          />
        </div>
        <div>
          {nodeInfo.ens && nodeInfo.ens.length > 0 && (
            <>
              <div className={style.infoLine}>
                <div className={style.info}>
                  <strong>ENS:</strong> {nodeInfo.reverseEns || nodeInfo.ens[0]}
                </div>
              </div>
              {nodeInfo.ens.length > 1 && (
                <div className={style.switchWrapper}>
                  <Switch
                    checked={ensVisible}
                    onChange={setEnsVisible}
                    size="small"
                  />
                  Show all ENS
                </div>
              )}
            </>
          )}

          {ensVisible && (
            <div className={style.ensList}>
              {nodeInfo.ens.map((item) => (
                <div className={style.ensItem} key={item}>
                  {item}
                </div>
              ))}
            </div>
          )}
        </div>
        <div style={{marginTop: '12px'}}>
          {nodeInfo.lensInclude && nodeInfo.lensInclude.length > 0 && (
            <>
              <div className={style.infoLine}>
                <div className={style.info}>
                  <strong>LENS:</strong> {nodeInfo.lensInclude[0].handle}
                </div>
              </div>
              {nodeInfo.lensInclude.length > 1 && (
                <div className={style.switchWrapper}>
                  <Switch
                    checked={lensVisible}
                    onChange={setLensVisible}
                    size="small"
                  />
                  Show all LENS
                </div>
              )}
            </>
          )}

          {lensVisible && (
            <div className={style.ensList}>
              {nodeInfo.lensInclude.map((item) => (
                <div className={style.ensItem} key={item}>
                  {item.handle}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={cn(style.info, style.accountType)}>
          <strong>Type:</strong> {addressType}
        </div>
      </div>

      {nodeInfo.holdNfts && nodeInfo.holdNfts.length > 0 && (
        <>
          <div className={style.divider} />
          <div>
            <div className={style.title}>
              NFT
              <br /> Collections
            </div>
            <div className={style.avatarList}>
              {nodeInfo.holdNfts
                .slice(
                  0,
                  allCollectionsVisible ? nodeInfo.holdNfts.length - 1 : 8
                )
                .map((item) => (
                  <AvatarItem
                    onClick={() => doHighlight(item.contract)}
                    key={item.contract}
                    img={item.imageUrl}
                    title={item.name || (item.contract === config.ensNftAddress ? 'ENS' : shortenAddr(item.contract))}
                  />
                ))}

              {hasMoreCollections && (
                <>
                  {allCollectionsVisible ? (
                    <a
                      className={style.toggleBtn}
                      onClick={() => setAllCollectionsVisible(false)}
                    >
                      Show less
                    </a>
                  ) : (
                    <a
                      className={style.toggleBtn}
                      onClick={() => setAllCollectionsVisible(true)}
                    >
                      Show more
                    </a>
                  )}
                </>
              )}
            </div>
          </div>
        </>
      )}

      {/** TODO Ask Errance */}
      {/* <div className={style.divider} />
      <div>
        <div className={style.title}>Web3 Connections</div>
        <div className={style.avatarList}>
          <AvatarItem img="/connections/0.png" title="Cheers" />
          <AvatarItem img="/connections/1.png" title="Lens" />
          <AvatarItem img="/connections/2.png" title="Shorum" />
        </div>
      </div> */}
    </div>
  );
}
