import React from "react";
import { Menu, message } from "antd";

export default function NodeMenu(props) {
  const { onFold, onFix, onUnfix, onExpand, onConfig } = props;
  const { nodeType, pagination, fixed, parent } = props.item._cfg.model;

  let nodeMenuItems = [];

  const commonMenus = [
    fixed ? { label: "Unpin", key: "unpin" } : { label: "Pin", key: "pin" },
    { label: "Customize", key: "customize" },
  ];

  if (nodeType === "address") {
    const handleChildren = [
      // { label: "Follower", key: "addrsFollow" },
      // { label: "Following", key: "followAddrs" },
      { label: "NFTs", key: "holdNfts" },
      { label: "Tokens", key: "holdTokens" },
      { label: "Lens Follow", key: "followLens" },
      { label: "Lens Profile", key: "lensInclude" },
      { label: "Events", key: "attendEvents" },
      { label: "Avatars", key: "avatarsInclude" },
      { label: "Governance", key: "voteSpaces" },
      { label: "Bits", key: "bitsInclude" },
    ];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: [...handleChildren, { label: "Fold all", key: "foldAll" }],
      },
      ...commonMenus,
    ];
  } else if (nodeType === "nft") {
    const handleChildren = [{ label: "Hold Address", key: "addrsHold" }];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "token") {
    const handleChildren = [{ label: "Hold Address", key: "addrsHold" }];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "twitter") {
    const handleChildren = [
      { label: "Include Avatars", key: "avatarsInclude" },
    ];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "avatar") {
    const handleChildren = [
      { label: "Include Address", key: "includeAddrs" },
      { label: "Include Twitters", key: "includeTwitters" },
    ];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: [...handleChildren, { label: "Fold all", key: "foldAll" }],
      },
      ...commonMenus,
    ];
  } else if (nodeType === "bit") {
    const handleChildren = [{ label: "Include Address", key: "includeAddrs" }];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "event") {
    const handleChildren = [{ label: "Attendee Address", key: "addrsAttend" }];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "space") {
    const handleChildren = [{ label: "Vote Address", key: "addrsVote" }];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: handleChildren,
      },
      ...commonMenus,
    ];
  } else if (nodeType === "lens") {
    const handleChildren = [
      { label: "Followers", key: "followerAddrs" },
      { label: "Owner", key: "owner" },
      // {
      //   label: "Posts",
      //   key: "publishPosts",
      // },
      // {
      //   label: "Comments",
      //   key: "publishComments",
      // },
      // {
      //   label: "Mirrors",
      //   key: "publishMirrors",
      // },
    ];
    nodeMenuItems = [
      {
        label: "Expand",
        key: "expand",
        children: handleChildren,
      },
      {
        label: "Fold",
        key: "fold",
        children: [...handleChildren, { label: "Fold all", key: "foldAll" }],
      },
      ...commonMenus,
    ];
  }

  const handleClick = (e) => {
    const { onClose, id } = props;
    const { key, keyPath } = e;
    if (key === "customize") {
      onConfig(nodeType);
    } else if (key === "foldAll") {
      onFold(id);
    } else if (key === "pin") {
      onFix(id, parent);
    } else if (key === "unpin") {
      onUnfix(id, parent);
    } else {
      if (keyPath[1] === "fold") {
        onFold(id, key);
      }

      if (keyPath[1] === "expand") {
        const currentPagination = pagination[key];
        if (!currentPagination.expandable) {
          message.info("You have expanded all nodes.");
          onClose();
          return;
        }
        onExpand({ nodeId: id, nodeType, pagination }, key);
      }
    }
    onClose();
  };
  return <Menu onClick={handleClick} items={nodeMenuItems} />;
}
