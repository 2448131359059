import React, { createRef, useEffect, useState } from "react";
import Graphin, { Behaviors, Components } from "@antv/graphin";
import NodeMenu from "../NodeMenu";
// import { exportCanvasToPNG } from "lib/tool";
const { ContextMenu } = Components;

export default function Graph({
  renderData,
  onNodeClick,
  onEdgeClick,
  onFold,
  onFix,
  onUnfix,
  onExpand,
  onConfig,
}) {
  const { ZoomCanvas, Hoverable, FontPaint, BrushSelect } = Behaviors;
  const graphRef = createRef(null);

  useEffect(() => {
    if (!graphRef || !graphRef.current) {
      return;
    }

    const { graph } = graphRef.current;

    if (!graph.watermarkerGenerated) {
      graph.setTextWaterMarker(["KNN3"], {
        width: 100,
        height: 80,
        text: {
          fill: "rgba(0, 0, 0, 0.08)",
          fontSize: "12px",
        },
      });
      graph.watermarkerGenerated = true;
    }

    graph.on("node:click", onNodeClick);
    graph.on("edge:click", onEdgeClick);

    return () => {
      graph.off("node:click", onNodeClick);
      graph.off("edge:click", onEdgeClick);
    };
  }, [graphRef]);

  return (
    <>
      <Graphin
        data={renderData}
        layout={{ type: "graphin-force" }}
        fitCenter={true}
        ref={graphRef}
      >
        <ContextMenu style={{ background: "#fff" }} bindType="node" >
          {(value) => (
            <NodeMenu
              {...value}
              onFold={onFold}
              onFix={onFix}
              onUnfix={onUnfix}
              onExpand={onExpand}
              onConfig={onConfig}
            />
          )}
        </ContextMenu>
        {/* <ContextMenu bindType="canvas">
          {(value) => <CanvasMenu {...value} />}
        </ContextMenu> */}
        <ZoomCanvas enableOptimize maxZoom={1} />
        <Hoverable bindType="node" />
        <Hoverable bindType="edge" />
        <FontPaint />
        <BrushSelect />
      </Graphin>
    </>
  );
}
