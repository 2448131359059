import React, { useState } from "react";
import { useRecoilState } from "recoil";
import { LoadingOutlined } from "@ant-design/icons";
import { settingState, nodeInfoState, nodeInfoLoadingState } from "store/atom";
import cn from "classnames";
import IconMenu from "assets/icons/sidebar-menu.png";

import IconGitbook from "assets/icons/gitbook.png";
import IconLeft from "assets/icons/icon-left.png";
import Address from "./comp/Address";
import Lens from "./comp/Lens";
// import Post from "./comp/Post";
// import Comment from "./comp/Comment";
// import Mirror from "./comp/Mirror";
import Nft from "./comp/Nft";
import Token from "./comp/Token";
import Event from "./comp/Event";
import Space from "./comp/Space";
import Bit from "./comp/Bit";
import Twitter from "./comp/Twitter";
import Avatar from "./comp/Avatar";
import style from "./style.module.scss";
import config from "config";

export default function Sidebar() {
  const [nodeInfo] = useRecoilState(nodeInfoState);
  const [nodeInfoLoading] = useRecoilState(nodeInfoLoadingState);
  const [opened, setOpened] = useState(true);

  return (
    <div className={cn(style.sidebar, !opened && style.closed)}>
      {/** TODO, fix graph not rerendering bug */}
      <img
        src={opened ? IconLeft : IconMenu}
        className={cn(style.iconMenu, opened && style.fixRight)}
        onClick={() => setOpened((prev) => !prev)}
      />
      {opened && (
        <div className={style.sidebarWrapper}>
          {nodeInfoLoading ? (
            <div>
              <LoadingOutlined className={style.loadingIcon} />
            </div>
          ) : (
            <div>
              {nodeInfo.nodeType === "address" && <Address />}
              {nodeInfo.nodeType === "lens" && <Lens />}
              {nodeInfo.nodeType === "nft" && <Nft />}
              {nodeInfo.nodeType === "token" && <Token />}
              {nodeInfo.nodeType === "event" && <Event />}
              {nodeInfo.nodeType === "twitter" && <Twitter />}
              {nodeInfo.nodeType === "avatar" && <Avatar />}
              {nodeInfo.nodeType === "space" && <Space />}
              {nodeInfo.nodeType === "bit" && <Bit />}
              {/* {nodeInfo.nodeType === "post" && <Post />}
              {nodeInfo.nodeType === "comment" && <Comment />}
              {nodeInfo.nodeType === "mirror" && <Mirror />} */}
            </div>
          )}

          <div className={style.sidebarBottom}>
            {/* <a className={style.btnSearchMode} onClick={toggleSearchMode}>
              {setting.searchMode === "input"
                ? "Search in GraphQL"
                : "Back to search"}
            </a> */}
            <a
              href={config.knn3Endpoint}
              className={style.btnSearchMode}
              target="_blank"
            >
              Search in GraphQL
            </a>
            <a href="https://docs.knn3.xyz/welcome/mashmesh" target="_blank">
              <img src={IconGitbook} className={style.gitbook} />
            </a>
          </div>
        </div>
      )}
    </div>
  );
}
