import {
  iconSizeMapping,
  nodeSizeMapping,
  defaultPagination,
  defaultPaginationObject,
} from "./constants";

export const getNode = (nodeInfo, parent = "", parentNodes = []) => {
  const { nodeId, nodeType, nodeLabel, nodeSize, nodeColor, nodeOrigin } =
    nodeInfo;
  let nodeStyle = {};

  if (nodeType === "address") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/address.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "nft") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/nft.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "token") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/token.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "twitter") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/twitter.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "avatar") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/avatar.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "event") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/event.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "space") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/space.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "bit") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/bit.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "lens") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/lens.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "post") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/lens.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "comment") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/lens.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  } else if (nodeType === "mirror") {
    nodeStyle = {
      icon: {
        type: "image",
        value: "/nodeIcon/lens.png",
        size: iconSizeMapping[nodeSize],
      },
      keyshape: {
        fill: nodeColor,
        size: nodeSizeMapping[nodeSize],
        fillOpacity: 1,
        stroke: "#575757",
        lineWidth: 2,
      },
    };
  }

  return {
    id: nodeId,
    fixed: false,
    nodeId,
    nodeType,
    nodeOrigin,
    // set default pagination for every nodes
    pagination: defaultPaginationObject,
    style: {
      label: {
        value: nodeLabel,
      },
      ...nodeStyle,
    },
    parent,
    parentNodes,
  };
};

export const getEdge = (
  source,
  target,
  label,
  parent = "",
  // relatedNodeType = "",
  parentNodes = []
) => {
  return {
    joinedId: `${source}-${target}`,
    source,
    target,
    style: {
      label: {
        fill: "#000",
        value: label,
      },
      keyshape: {
        stroke: "#979797",
      },
    },
    parent,
    // relatedNodeType,
    parentNodes,
  };
};

export const getTargetScript = (val, pagi) => {
  pagi = JSON.stringify({ offset: pagi.offset, limit: pagi.limit + 1 }).replace(
    /"/g,
    ""
  );

  switch (val) {
    case "addrsFollow":
      return `addrsFollow(options:${pagi}) {
          address,
          name,
          ens,
          reverseEns,
        },
        `;
    case "followAddrs":
      return `
        followAddrs(options:${pagi}) {
          address,
          name,
          ens,
          reverseEns,
        }
        `;
    case "holdNfts":
      return `holdNfts(options:${pagi}) {
          name,
          contract,
          imageUrl
        }
       `;
    case "holdTokens":
      return `holdTokens(options:${pagi}) {
          name,
          symbol,
          contract,
        }
       `;
    case "attendEvents":
      return `attendEvents(options:${pagi}) {
          name,
          id,
          imageUrl,
        },
       `;
    case "addrsHold":
      return `
        addrsHold(options:${pagi}){
          address,
          name,
          ens,
        }
        `;
    case "addrsAttend":
      return `
        addrsAttend(options:${pagi}) {
          address,
          name,
          ens,
        }
        `;
    case "addrsVote":
      return `
        addrsVote(options:${pagi}) {
          address,
          name,
          ens,
        }
        `;
    case "voteSpaces":
      return `
        voteSpaces(options:${pagi}) {
          id,
          name,
          avatar,
        }
        `;
    case "includeAddrs":
      return `
        includeAddrs(options:${pagi}){
          address,
          name,
          ens,
        }
          `;
    case "bitsInclude":
      return `
          bitsInclude(options:${pagi}){
            account,
          },
        `;
    case "includeTwitters":
      return `
        includeTwitters(options:${pagi}){
          name
        }
        `;
    case "avatarsInclude":
      return `
        avatarsInclude(options:${pagi}){
          id,
        }
        `;
    case "followerAddrs":
      return `
        followerAddrs(options:${pagi}){
          address,
          name,
          ens,
        }
      `;
    case "lensInclude":
      return `
        lensInclude(options:${pagi}){
          address,
          handle,
          profileId,
        }
      `;
    case "followLens":
      return `
        followLens(options:${pagi}){
          address,
          handle,
          profileId,
        }
      `;
    case "publishComments":
      return `
            publishComments(options:${pagi}){
                id,
                pubId,
                profileId,
            }
        `;
    case "publishPosts":
      return `
            publishPosts(options:${pagi}){
                id,
                pubId,
                profileId,
            }
        `;
    case "publishMirrors":
      return `
            publishMirrors(options:${pagi}){
                id
                pubId,
                profileId,
            }
        `;
  }
};

// find current active node and add pagination to it. todo, handle pagination later
export const handlePaginationForActiveNode = (
  originalGraphData,
  nodeValue,
  target,
  resLength
) => {
  originalGraphData.nodes
    .filter((item) => item.id === nodeValue)
    .forEach((targetNode) => {
      const previousPaginationOffset = targetNode?.pagination[target]?.offset;
      targetNode.pagination = {
        ...targetNode.pagination,
        [target]: {
          offset: previousPaginationOffset + defaultPagination.limit,
          limit: defaultPagination.limit,
          expandable: resLength > defaultPagination.limit,
        },
      };
    });
  return originalGraphData;
};
