import { request, gql, GraphQLClient } from "graphql-request";
import graph from "./graph";
import config from "../../config";

const client = new GraphQLClient(config.knn3Endpoint);

const getENS = async (val) => {
  const query = gql`
  {
    ens(where:{ens:"${val}"}, options: {limit:5}){
      ens,
      address,
    }
  }
`;

  return (await client.request({ document: query })).ens;
};

const getNFT = async (val) => {
  const query = gql`
    {
      nfts(where:{contract: "${val}"}, options: {limit:5}){
        name,
        contract,
        imageUrl,
        network,
        primaryInterface,
        symbol,
      }
    }
  `;

  return (await client.request({ document: query })).nfts;
};

const getToken = async (val) => {
  const query = gql`
    {
      tokens(where:{contract: "${val}"}, options: {limit:5}){
        name,
        contract,
        symbol,
      }
    }
  `;

  return (await client.request({ document: query })).tokens;
};

const getAvatar = async (val) => {
  const query = gql`
    {
      avatars(where: {id: "${val}"}, options: { limit: 5 }) {
        id
      }
    }
  `;

  return (await client.request({ document: query })).avatars;
};

const getTwitter = async (val) => {
  const query = gql`
    {
      twitters(where:{ name: "${val}"}, options: {limit:5}){
        name,
      }
    }
  `;

  return (await client.request({ document: query })).twitters;
};

const getEvent = async (val) => {
  const query = gql`
    {
      events(where:{id: "${val}"}, options: { limit: 5 }){
        id,
        name,
        imageUrl,
      }
    }
  `;

  return (await client.request({ document: query })).events;
};

const getSpace = async (val) => {
  const query = gql`
    {
      spaces(where:{id: "${val}"}, options: { limit: 5 }){
        id,
        name,
        avatar,
      }
    }
  `;

  return (await client.request({ document: query })).spaces;
};

const getLens = async (val) => {
  const query = gql`
    {
      lens(where:{handle: "${val}"}, options: { limit: 5 }){
        handle,
        profileId,
        imageURI,
        value,
        creator,
        address,
        followModule,
      }
    }
  `;

  return (await client.request({ document: query })).lens;
};

const getPost = async (val) => {
  const query = gql`
    {
      lens_post(where:{id: "${val}"}, options: { limit: 5 }){
        id,
        pubId,
        contentURI,
        profileIdPointed,
      }
    }
  `;

  return (await client.request({ document: query })).lensPost;
};

const getComment = async (val) => {
  const query = gql`
    {
      lens_comment(where:{id: "${val}"}, options: { limit: 5 }){
        id,
        pubId,
        contentURI,
        profileIdPointed,
        pubIdPointed,
      }
    }
  `;

  return (await client.request({ document: query })).lensComment;
};

const getMirror = async (val) => {
  const query = gql`
    {
      lens_mirror(where:{id: "${val}"}, options: { limit: 5 }){
        id,
        pubId,
        contentURI,
        profileIdPointed,
        pubIdPointed,
      }
    }
  `;

  return (await client.request({ document: query })).lensMirror;
};

const getAddress = async (address) => {
  const query = gql`
    {
      addrs(where:{address: "${address}"}){
        address,
        name,
        reverseEns,
        ens,
        lensInclude{
          handle
        }
        holdNfts(options:{limit: 50}) {
          name,
          contract,
          imageUrl
        }
      }
    }
  `;

  return (await client.request({ document: query })).addrs;
};

const searchByValue = async (value, filters) => {
  const query = gql`
    {
      ${filters.indexOf('address') > -1 ? `
        addrs(where:{address_STARTS_WITH: "${value}"}, options: {limit: 5}){
          address,
          name,
          ens,
        }
      ` : ''}
      ${filters.indexOf('domain') > -1 ? `
        ens(where:{ens_STARTS_WITH: "${value}"}, options: {limit:5}){
          ens,
          address,
        }
        bits(where:{account_STARTS_WITH: "${value}"}, options: {limit:5}){
          account
        }
      ` : ''}
      ${filters.indexOf('nft') > -1 ? `
        nfts(where:{OR: [{name_STARTS_WITH: "${value}"}, {symbol_STARTS_WITH: "${value}"}, {contract_STARTS_WITH: "${value}" }]}, options: {limit:5}){
          name,
          contract,
          imageUrl,
          network,
          primaryInterface,
          symbol,
        }
      ` : ''}
      ${filters.indexOf('token') > -1 ? `
        tokens(where:{OR: [{name_STARTS_WITH: "${value}"}, {symbol_STARTS_WITH: "${value}"}, {contract_STARTS_WITH: "${value}" }]}, options: {limit:5}){
          name,
          contract,
          symbol,
          decimal,
        }
      ` : ''}
      ${filters.indexOf('avatar') > -1 ? `
        avatars(where: {id_STARTS_WITH: "${value}"}, options: { limit: 5 }) {
          id
        }

        twitters(where:{name_STARTS_WITH: "${value}" }, options: {limit:5}){
          name,
        }
      ` : ''}
      ${filters.indexOf('event') > -1 ? `
        events(where:{OR: [{name_STARTS_WITH: "${value}"}, {id_STARTS_WITH: "${value}"}]}, options: { limit: 5 }){
          id,
          name,
          imageUrl,
        }
      ` : ''}
      ${filters.indexOf('space') > -1 ? `
        spaces(where:{id_STARTS_WITH: "${value}"}, options: { limit: 5 }){
          id,
          name,
          avatar,
        }
      ` : ''}
      ${filters.indexOf('lens') > -1 ? `
      lens(where:{handle_STARTS_WITH: "${value}"}, options: { limit: 5 }){
        profileId,
        handle,
        value,
      }
    ` : ''}
    }
  `;

  return await client.request({ document: query });
};

export default {
  getENS,
  getNFT,
  getToken,
  getEvent,
  getAddress,

  getAvatar,
  getTwitter,
  getSpace,
  getLens,
  getPost,
  getComment,
  getMirror,

  searchByValue,

  graph,
};
