import React, { useContext, useEffect, useState } from "react";
import cn from "classnames";
import { userAssetsState } from "store/atom";
import { useRecoilState } from "recoil";
import knn3 from "lib/knn3";
import WalletBtnTips from "components/WalletBtnTips";
import useWeb3Context from "hooks/useWeb3Context";
import useKNN3ProfileClientContract from "contract/useKNN3ProfileClientContract";
import style from "./style.module.scss";

export default function WalletButton({ menuLight, isTrans }) {
  const [ensName, setEnsName] = useState("");
  const { account, connectWallet, resetWallet, getAddressDefaultEns } =
    useWeb3Context();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [tipsVisible, setTipsVisible] = useState(false);
  const [, setUserAssets] = useRecoilState(userAssetsState);
  const chainLinkContract = useKNN3ProfileClientContract();

  const getUserAssets = async () => {
    // const testAddress = "0xd77Dd8754c5f3c63BA18cD17bf2f6dC60EE14E6F";
    const res = await chainLinkContract.getAssetsInfo(account);

    setUserAssets({
      addr: account,
      ens: res.basicProfile.ens,
      twitterName: res.basicProfile.twitter_name,
      snapshotNum: res.governance.snapshot || 0,
      snapshotLink: res.governance.snapshot_link,
      nftNum: res.assetsAndDefi.nft || 0,
      nftLink: res.assetsAndDefi.nft_link,
      ifStaking:
        res.assetsAndDefi.if_staking &&
        JSON.parse(res.assetsAndDefi.if_staking),
      follower:
        res.socialStatus.follower && JSON.parse(res.socialStatus.follower),
      following:
        res.socialStatus.following && JSON.parse(res.socialStatus.following),
    });
  };

  const getEnsName = async () => {
    const res = await knn3.getAddress(account);
    if (res.length > 0) {
      setEnsName(res[0].reverseEns);
    }
  };

  useEffect(() => {
    if (!account) {
      return;
    }
    getUserAssets();
    getEnsName();
  }, [account]);

  let timer = null;

  const onMouseEnterWalletButton = () => {
    clearTimeout(timer);
    setDropdownVisible(true);
  };

  const onMouseLeaveWalletButton = () => {
    timer = setTimeout(() => {
      setDropdownVisible(false);
    }, 100);
  };

  return account ? (
    <>
      <div
        className={cn(style.walletBtn)}
        // onClick={() => setTipsVisible((prev) => !prev)}
        onMouseEnter={onMouseEnterWalletButton}
        onMouseLeave={onMouseLeaveWalletButton}
      >
        {ensName ? ensName : `${account.slice(0, 4)}...${account.slice(-4)}`}
      </div>
      {dropdownVisible && (
        <div
          className={cn(
            style.walletBtn,
            style.walletMenu,
            menuLight && style.menuLight,
            !menuLight && style.menuHeader
          )}
          onClick={resetWallet}
          onMouseEnter={onMouseEnterWalletButton}
          onMouseLeave={onMouseLeaveWalletButton}
        >
          Disconnect
        </div>
      )}

      {tipsVisible && (
        <div
          className={cn(
            menuLight && style.walletTisDiv,
            !menuLight && style.walletTisHeaderDiv
          )}
        >
          <WalletBtnTips />
        </div>
      )}
    </>
  ) : (
    <>
      <div
        className={cn(style.walletBtn, isTrans && style.isTrans)}
        onClick={connectWallet}
      >
        Connect wallet
      </div>
    </>
  );
}
