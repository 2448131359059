import React, { useState, useEffect } from "react";
import cn from "classnames";
import { Tooltip, Checkbox } from "antd";
import { useHistory } from "react-router-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { LoadingOutlined } from "@ant-design/icons";
import IconQuestion from "assets/icons/question.png";
import IconQuestionDark from "assets/icons/question-dark.svg";
import knn3 from "../../lib/knn3";
import BN from "bignumber.js";
import IconSearch from "assets/icons/search.png";
import IconSearchLight from "assets/icons/search-light.png";
import IconClose from "assets/icons/closer.svg";
import style from "./style.module.scss";

const initResult = {
  ens: [],
  nfts: [],
  tokens: [],
  events: [],
  addrs: [],
  avatars: [],
  twitters: [],
  spaces: [],
  bits: [],
  lens: [],
};

const filterOptions = [
  {
    title: "Avatar",
    value: "avatar",
    tooltip:
      "Refer to avatars (in Next.id) and their bonded Twitter accounts（search by handle）",
  },
  {
    title: "Address",
    value: "address",
  },
  {
    title: "Event",
    value: "event",
  },
  {
    title: "Token",
    value: "token",
  },
  {
    title: "NFT",
    value: "nft",
  },
  {
    title: "Space",
    value: "space",
  },
  {
    title: "Domain",
    value: "domain",
    tooltip: "Include ENS and .bit",
  },
  {
    title: "Lens",
    value: "lens",
  },
];

export default function Search({ isHeader, isTrans }) {
  const routerHistory = useHistory();
  const [result, setResult] = useState(initResult);
  const [loading, setLoading] = useState(false);
  const [searchFilters, setSearchFilters] = useState([
    "address",
    "nft",
    "domain",
    "lens",
  ]);
  const [search, setSearch] = useState("");
  const [timer, setTimer] = useState(null);
  const [isEmpty, setIsEmpty] = useState(false);
  const [focusing, setFocusing] = useState(false);

  const debounce = (fn, delay) => {
    clearTimeout(timer);
    setTimer(setTimeout(fn, delay));
  };

  const searchByValue = async () => {
    const res = await knn3.searchByValue(search, searchFilters);
    setResult(res);
  };

  const clearSearch = () => {
    setSearch("");
    setResult(initResult);
  };

  const doSearch = async () => {
    if (loading) {
      setResult(initResult);
    }

    await searchByValue();

    setLoading(false);
  };

  const goLink = (url, searchItem) => {
    // save to history
    let history = JSON.parse(localStorage.getItem("searchHistory") || "[]");
    const historyIds = history.filter((item) => item).map((item) => item.id);
    if (historyIds.indexOf(searchItem.id) === -1) {
      history.unshift(searchItem);
      history.length = 5;
      localStorage.setItem("searchHistory", JSON.stringify(history));
    }
    setSearch("");
    setFocusing(false);
    routerHistory.push(url);
  };

  const doHistorySearch = () => {
    const history = JSON.parse(
      localStorage.getItem("searchHistory") || "[]"
    ).filter((item) => item);
    if (history.length === 0) {
      return;
    }
    setFocusing(true);
    let historyResult = JSON.parse(JSON.stringify(initResult));
    history.forEach((item) => {
      historyResult[item.source].push(item);
    });
    setResult(historyResult);
  };

  const SuggestionItem = ({ title, list, type }) => {
    return (
      <div className={style.section}>
        <div className={style.title}>{title}</div>
        <div className={style.content}>
          {type === "address" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/address/${item.address}`, {
                    id: item.address,
                    source: "addrs",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                {item.address}
              </a>
            ))}

          {type === "ens" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/address/${item.address}`, {
                    id: item.address,
                    source: "ens",
                    ...item,
                  })
                }
                key={index}
                className={style.item}
              >
                <div>{item.ens}</div>
                {item.address && (
                  <div>
                    {item.address.slice(0, 4)}...{item.address.slice(-4)}
                  </div>
                )}
              </a>
            ))}

          {type === "nft" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/nft/${item.contract}`, {
                    source: "nfts",
                    id: item.contract,
                    ...item,
                  })
                }
                key={index}
                className={style.item}
              >
                <div className={style.nameWrapper}>
                  <img
                    src={item.imageUrl ? item.imageUrl : "/nft-placeholder.png"}
                    className={style.icon}
                  />
                  {item.name}
                </div>
                {item.contract && (
                  <div>
                    {item.contract.slice(0, 4)}...{item.contract.slice(-4)}
                    {item.network && (
                      <span style={{ marginLeft: "4px" }}>
                        ({item.network.toUpperCase()})
                      </span>
                    )}
                  </div>
                )}
              </a>
            ))}

          {type === "token" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/token/${item.contract}`, {
                    id: item.contract,
                    source: "tokens",
                    ...item,
                  })
                }
                key={index}
                className={style.item}
              >
                <div className={style.nameWrapper}>{item.symbol}</div>
                {item.contract && (
                  <div>
                    {item.contract.slice(0, 4)}...{item.contract.slice(-4)}
                  </div>
                )}
              </a>
            ))}

          {type === "event" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/event/${item.id}`, {
                    id: item.id,
                    source: "events",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div className={style.nameWrapper}>
                  {item.imageUrl && (
                    <img src={item.imageUrl} className={style.icon} />
                  )}{" "}
                  {item.name}
                </div>
                <div>ID: {item.id}</div>
              </a>
            ))}

          {type === "space" &&
            list[0].map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/space/${item.id}`, {
                    id: item.id,
                    source: "spaces",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div className={style.nameWrapper}>
                  {item.avatar && (
                    <img src={item.avatar} className={style.icon} />
                  )}{" "}
                  {item.name}
                </div>
                <div>ID: {item.id}</div>
              </a>
            ))}

          {type === "avatar" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/avatar/${item.id}`, {
                    id: item.id,
                    source: "avatars",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div>ID: {item.id}</div>
              </a>
            ))}

          {type === "twitter" &&
            list.map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/twitter/${item.name}`, {
                    id: item.name,
                    source: "twitters",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div>ID: {item.name}</div>
              </a>
            ))}

          {type === "bit" &&
            list[0].map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/bit/${item.account}`, {
                    id: item.account,
                    source: "bits",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div>{item.account}</div>
              </a>
            ))}

          {type === "lens" &&
            list[0].map((item, index) => (
              <a
                onClick={() =>
                  goLink(`/dashboard/lens/${item.handle}`, {
                    id: item.handle,
                    source: "lens",
                    ...item,
                  })
                }
                className={style.item}
                key={index}
              >
                <div>{item.handle}</div>
                {item.value && (
                  <div>Score: {new BN(item.value).times(10).toFixed(5)}</div>
                )}
              </a>
            ))}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!search) {
      return;
    }
    setLoading(true);
    debounce(doSearch, 1000);
  }, [search]);

  const checkIsEmpty = () => {
    let empty = true;
    if (
      (result.ens && result.ens.length > 0) ||
      (result.nfts && result.nfts.length > 0) ||
      (result.events && result.events.length > 0) ||
      (result.addrs && result.addrs.length > 0) ||
      (result.avatars && result.avatars.length > 0) ||
      (result.spaces && result.spaces.length > 0) ||
      (result.twitters && result.twitters.length > 0) ||
      (result.bits && result.bits.length > 0) ||
      (result.tokens && result.tokens.length > 0) ||
      (result.lens && result.lens.length > 0)
    ) {
      empty = false;
    }
    setIsEmpty(empty);
  };

  useEffect(() => {
    checkIsEmpty();
  }, [result]);

  const SearchFilter = ({ lightMode }) => {
    return (
      <div className="text-left">
        <Checkbox.Group value={searchFilters} onChange={setSearchFilters}>
          <div className={style.searchFilter}>
            {filterOptions.map((item) => (
              <div className={style.searchItem} key={item.value}>
                <Checkbox value={item.value}>{item.title}</Checkbox>
                {item.tooltip && (
                  <Tooltip
                    title={item.tooltip}
                    overlayClassName="tooltip-white"
                  >
                    <img src={lightMode ? IconQuestionDark : IconQuestion} />
                  </Tooltip>
                )}
              </div>
            ))}
          </div>
        </Checkbox.Group>
      </div>
    );
  };

  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setSearch("");
        setFocusing(false);
      }}
    >
      {!isHeader && (
        <div className={style.searchFilterTopWrapper}>
          <SearchFilter />
        </div>
      )}
      <div
        className={cn(
          style.searchWrapper,
          (search || focusing) && style.withResult,
          isHeader && style.isHeader,
          isTrans && !(search || focusing) && style.isTrans
        )}
      >
        <div className={cn(style.search, search && style.withResult)}>
          <img
            src={
              isTrans && !(focusing || search) ? IconSearchLight : IconSearch
            }
            className={style.iconSearch}
            alt="icon-search"
          />
          <input
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            type="text"
            className={style.input}
            onFocus={() => {
              !search && doHistorySearch();
            }}
            placeholder="Search by Address, avatar or name of projects..."
          />
          {search && (
            <img
              src={IconClose}
              className={style.iconClose}
              onClick={clearSearch}
            />
          )}

          {/* {invalid && <div className={style.invalidHint}>Invalid entity</div>} */}
        </div>
        {isHeader && focusing && (
          <div className={style.searchFilterWrapper}>
            <SearchFilter lightMode={true} />
          </div>
        )}
        {(search || focusing) && (
          <div className={style.suggestion}>
            {loading && (
              <div className={style.hint}>
                <LoadingOutlined /> Loading...
              </div>
            )}
            {isEmpty && !loading && !focusing && (
              <div className={style.hint}>Entity not found</div>
            )}

            <>
              {result.addrs && result.addrs.length > 0 && (
                <SuggestionItem
                  title="Address"
                  list={result.addrs}
                  type="address"
                />
              )}

              {result.nfts && result.nfts.length > 0 && (
                <SuggestionItem
                  title="Collections"
                  list={result.nfts}
                  type="nft"
                />
              )}

              {result.tokens && result.tokens.length > 0 && (
                <SuggestionItem
                  title="Tokens"
                  list={result.tokens}
                  type="token"
                />
              )}

              {result.ens && result.ens.length > 0 && (
                <SuggestionItem title="ENS" list={result.ens} type="ens" />
              )}

              {result.events && result.events.length > 0 && (
                <SuggestionItem
                  title="Events"
                  list={result.events}
                  type="event"
                />
              )}

              {result.avatars && result.avatars.length > 0 && (
                <SuggestionItem
                  title="avatar"
                  list={result.avatars}
                  type="avatar"
                />
              )}

              {result.twitters && result.twitters.length > 0 && (
                <SuggestionItem
                  title="Twitter"
                  list={result.twitters}
                  type="twitter"
                />
              )}
              {result.spaces && result.spaces.length > 0 && (
                <SuggestionItem
                  title="Space"
                  list={[result.spaces]}
                  type="space"
                />
              )}
              {result.bits && result.bits.length > 0 && (
                <SuggestionItem title="Bit" list={[result.bits]} type="bit" />
              )}
              {result.lens && result.lens.length > 0 && (
                <SuggestionItem title="Lens" list={[result.lens]} type="lens" />
              )}
            </>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
}
