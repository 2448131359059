import axios from "axios";
import config from "config";

const api = axios.create({
  baseURL: config.baseURL,
});

// api.interceptors.response.use((res) => {
//   if (res.data.code === 200) {
//     return res.data;
//   } else {
//     message.error(res.data.message);
//     return null;
//   }
// });

const log = {
  create: async (params) => {
    // current timestamp
    params.timestamp = parseInt(new Date().valueOf() / 1000);
    api.post("/api/log", params);
  },
};

export default {
  log,
};
