import React from "react";
import Search from "../../components/Search";
import Socials from "components/Socials";
import WalletButton from "components/WalletButton";
import LogoRed from "../../assets/logo-red.png";
import LogoHome from "../../assets/logo.gif";
import style from "./style.module.scss";

export default function Home() {
  return (
    <div className={style.homepage}>
      <div className={style.top}>
        <div className={style.slogan}>
          <div>Web3 Relationship Explorer</div>
          <div className={style.poweredBy}>
            Powered by <img src={LogoRed} className={style.logoRed} />
          </div>
        </div>

        <div className={style.walletWrapper}>
          <WalletButton menuLight={true} isTrans={true} />
        </div>
      </div>

      <div className="container">
        <div className={style.sloganWrapper}>
          <img src={LogoHome} className={style.logoHome} />
        </div>

        <Search isTrans={true} />

        {/* {tab === tabs[0] && <Search />}
        {tab === tabs[1] && <GraphQLEditor />} */}

        {/* <div className={style.switchWrapper}>
          {tab === tabs[0] && (
            <>
              <a className={style.switchBtn} onClick={() => setTab(tabs[1])}>
                Search in GraphQL
              </a>
            </>
          )}
          {tab === tabs[1] && (
            <a className={style.switchBtn} onClick={() => setTab(tabs[0])}>
              Back to search
            </a>
          )}
        </div> */}
      </div>
      <Socials />
    </div>
  );
}
