import React, { useState } from "react";

import style from "./style.module.scss";

export default function AvatarItem({ img, title, amount, link, onClick }) {
  const [imgError, setImgError] = useState(false);
  return (
    <a
      className={style.avatarItem}
      href={link}
      onClick={() => onClick && onClick()}
    >
      <img
        src={
          imgError || !img
            ? "/nft-placeholder.png"
            : img.replace(
                "https://s3.us-west-1.amazonaws.com/knn3-static-logo",
                "https://pz-hvfkok.meson.network"
              )
        }
        onError={() => setImgError(true)}
        className={style.img}
      />
      {title && <div className={style.title}>{title}</div>}
      {amount && <div className={style.amount}>{amount}</div>}
    </a>
  );
}
