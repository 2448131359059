import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconTwitter from "assets/icons/twitter.png";

import style from "../style.module.scss";

export default () => {
  const [nodeInfo] = useRecoilState(nodeInfoState);
  return (
    <div>
      <div className={style.title}>Twitter</div>
      <PropertyLine icon={IconTwitter} title="Handle" value={nodeInfo.id} />
    </div>
  );
};
