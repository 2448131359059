import { gql, GraphQLClient } from "graphql-request";
import config from "config";
import {
  getNode,
  getEdge,
  getTargetScript,
  handlePaginationForActiveNode,
} from "./utils";
import {
  nodeSizeMapping,
  iconSizeMapping,
  defaultPaginationObject,
} from "./constants";
import Graphin, { Utils } from "@antv/graphin";
import { message } from "antd";
import { getNodeLabel, filterDuplicateNodes } from "../tool";
import IconLoader from "@antv/graphin-icons";

const icons = Graphin.registerFontFamily(IconLoader);

const client = new GraphQLClient(config.knn3Endpoint);

const handleMultipleEdges = (edges) => {
  const res = filterDuplicateNodes(edges, "joinedId");
  return Utils.processEdges(res);
};

const regenerate = async (entityConfig, highlightNode, graphData) => {
  let highlightFound = false;

  graphData.nodes.forEach((item) => {
    const { nodeType } = item;

    if (highlightNode === item.id) {
      highlightFound = true;
    }

    const nodeConfig = entityConfig[nodeType];
    item.style = {
      // todo, there's a bug with graphin, will not render immediately
      icon: {
        ...item.style.icon,
        size: iconSizeMapping[nodeConfig.size],
      },
      label: {
        value: getNodeLabel(item.originalItem, nodeType, nodeConfig.caption),
      },
      keyshape: {
        fill: nodeConfig.color,
        size: nodeSizeMapping[nodeConfig.size],
      },
    };
    item.status = {
      selected: highlightNode === item.id,
    };
  });

  if (highlightNode && !highlightFound) {
    message.info("Highlight node is not found, please keep expanding.");
  }
  return graphData;
};

const getRelations = async (
  nodeInfo,
  target,
  entityConfig,
  originalGraphData
) => {
  const { nodeId, nodeType, pagination } = nodeInfo;
  // force address lowercase
  if (nodeType === "address") {
    return await getAddressRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "nft") {
    return await getNftRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "token") {
    return await getTokenRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "twitter") {
    return await getTwitterRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "avatar") {
    return await getAvatarRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "event") {
    return await getEventRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "space") {
    return await getSpaceRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "bit") {
    return await getBitRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  } else if (nodeType === "lens") {
    return await getLensRelations(
      nodeId,
      target,
      pagination[target],
      entityConfig,
      originalGraphData
    );
  }
};

const foldNodeRelations = async (id, nodeOrigin, originalGraphData) => {
  let nextNodes = [];
  let nextEdges = [];

  let prevNodes = originalGraphData.nodes;
  let prevEdges = originalGraphData.edges;

  // fold all children
  let nodesToRemove = [];
  let edgesToRemove = [];

  // fold certain node type
  if (nodeOrigin) {
    prevNodes.forEach((item) => {
      if (item.parent === id && item.nodeOrigin === nodeOrigin && !item.fixed) {
        // remove self
        nodesToRemove.push(item.id);
        // remove all children
        prevNodes.forEach((i) => {
          if (i.parentNodes.indexOf(item.id) > -1) {
            nodesToRemove.push(i.id);
          }
        });
      }
    });
  } else {
    prevNodes.forEach((item) => {
      if (
        item.parentNodes.indexOf(id) > -1 &&
        !(item.fixed && item.parent === id)
      ) {
        nodesToRemove.push(item.id);
      }
    });
  }

  prevEdges.forEach((item) => {
    if (
      nodesToRemove.indexOf(item.source) > -1 ||
      nodesToRemove.indexOf(item.target) > -1
    ) {
      edgesToRemove.push(item.id);
    }
  });

  nextNodes = prevNodes.filter((item) => nodesToRemove.indexOf(item.id) === -1);
  nextEdges = prevEdges.filter((item) => edgesToRemove.indexOf(item.id) === -1);

  // clear current pagination
  nextNodes
    .filter((item) => item.id === id)
    .forEach((item) => (item.pagination = defaultPaginationObject));

  return {
    nodes: nextNodes,
    edges: handleMultipleEdges(nextEdges),
  };
};

const fixNode = async (id, parentId, originalGraphData) => {
  let nextNodes = [];

  let prevNodes = originalGraphData.nodes;
  let prevEdges = originalGraphData.edges;

  nextNodes = prevNodes.map((item) => {
    if (item.id === id) {
      item.fixed = true;
      item.style.badges = [
        {
          position: "RT",
          type: "font",
          fontFamily: "graphin",
          value: icons.pushpin,
          size: [20, 20],
          color: "#fff",
          fill: "#000",
        },
      ];
    }
    return item;
  });

  prevEdges.forEach((item) => {
    if (
      (item.source === parentId && item.target === id) ||
      (item.source === id && item.target === parentId)
    ) {
      item.fixed = true;
    } else {
      item.fixed = false;
    }
  });

  return {
    nodes: nextNodes,
    edges: handleMultipleEdges(prevEdges),
  };
};

const unfixNode = async (id, parentId, originalGraphData) => {
  let nextNodes = [];

  let prevNodes = originalGraphData.nodes;
  let prevEdges = originalGraphData.edges;

  nextNodes = prevNodes.map((item) => {
    if (item.id === id) {
      item.fixed = false;
      item.style.badges = [];
    }
    return item;
  });

  prevEdges.forEach((item) => {
    if (
      (item.source === parentId && item.target === id) ||
      (item.source === id && item.target === parentId)
    ) {
      item.fixed = false;
    }
  });

  return {
    nodes: nextNodes,
    edges: handleMultipleEdges(prevEdges),
  };
};

const getRootNode = async (nodeInfo, entityConfig, originalItem) => {
  const { nodeType } = nodeInfo;
  nodeInfo.nodeSize = entityConfig[nodeType].size;
  nodeInfo.nodeColor = entityConfig[nodeType].color;
  const rootNode = getNode(nodeInfo);
  rootNode.originalItem = originalItem;
  return {
    nodes: [rootNode],
    edges: [],
  };
};

const checkResponseLength = (list) => {
  if (list.length > 0) {
    return true;
  } else {
    throw new Error("You have expanded all nodes.");
  }
};

const getAddressRelations = async (
  address,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  // add pagination config here
  const targetScript = getTargetScript(target, pagi);
  // get element who triggered expand
  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === address
  )[0];

  const query = gql`
      {
        addrs(where: { address: "${address}"}) {
          address,
          name,
          ens,
          ${targetScript},
        }
      }
    `;

  let res = (await client.request(query)).addrs[0];

  if (res) {
    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      address,
      target,
      res[target].length
    );

    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.address)
      : [res.address];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    if (target === "addrsFollow") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(
            item.address,
            res.address,
            "Follow",
            res.address,
            // "address",
            parentNodes
          )
        );
      });
    }

    if (target === "avatarsInclude") {
      if (!checkResponseLength(res[target])) {
        return;
      }

      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.id) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.id,
                nodeType: "avatar",
                nodeLabel: getNodeLabel(
                  item,
                  "avatar",
                  entityConfig["avatar"].caption
                ),
                nodeSize: entityConfig["avatar"].size,
                nodeColor: entityConfig["avatar"].color,
                nodeOrigin: target,
              },
              address,
              parentNodes
            ),
            originalItem: item,
          });
        }

        // address => bond => item.id
        originalGraphData.edges.push(
          getEdge(
            address,
            item.id,
            "Bond",
            address,
            // "avatar",
            parentNodes
          )
        );
      });
    }

    if (target === "followAddrs") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.address, item.address, "Follow", res.address, parentNodes)
        );
      });
    }

    if (target === "holdNfts") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.contract) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.contract,
                nodeType: "nft",
                nodeLabel: getNodeLabel(
                  item,
                  "nft",
                  entityConfig["nft"].caption
                ),
                nodeSize: entityConfig["nft"].size,
                nodeColor: entityConfig["nft"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }

        originalGraphData.edges.push(
          getEdge(res.address, item.contract, "Hold", res.address, parentNodes)
        );
      });
    }

    if (target === "lensInclude") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.handle) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.handle,
                nodeType: "lens",
                nodeLabel: getNodeLabel(
                  item,
                  "lens",
                  entityConfig["lens"].caption
                ),
                nodeSize: entityConfig["lens"].size,
                nodeColor: entityConfig["lens"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }

        originalGraphData.edges.push(
          getEdge(res.address, item.handle, "Own", res.address, parentNodes)
        );
      });
    }

    if (target === "holdTokens") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.contract) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.contract,
                nodeType: "token",
                nodeLabel: getNodeLabel(
                  item,
                  "token",
                  entityConfig["token"].caption
                ),
                nodeSize: entityConfig["token"].size,
                nodeColor: entityConfig["token"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }

        originalGraphData.edges.push(
          getEdge(
            res.address,
            item.contract,
            "Hold",
            res.address,
            // "token",
            parentNodes
          )
        );
      });
    }

    if (target === "attendEvents") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.id) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.id,
                nodeType: "event",
                nodeLabel: getNodeLabel(
                  item,
                  "event",
                  entityConfig["event"].caption
                ),
                nodeSize: entityConfig["event"].size,
                nodeColor: entityConfig["event"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.address, item.id, "Attend", res.address, parentNodes)
        );
      });
    }

    if (target === "voteSpaces") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.id) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.id,
                nodeType: "space",
                nodeLabel: getNodeLabel(
                  item,
                  "space",
                  entityConfig["space"].caption
                ),
                nodeSize: entityConfig["space"].size,
                nodeColor: entityConfig["space"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.address, item.id, "Vote", res.address, parentNodes)
        );
      });
    }

    if (target === "followLens") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.handle) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.handle,
                nodeType: "lens",
                nodeLabel: getNodeLabel(
                  item,
                  "lens",
                  entityConfig["lens"].caption
                ),
                nodeSize: entityConfig["lens"].size,
                nodeColor: entityConfig["lens"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.address, item.handle, "Follow", res.address, parentNodes)
        );
      });
    }

    if (target === "bitsInclude") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.account) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.account,
                nodeType: "bit",
                nodeLabel: getNodeLabel(
                  item,
                  "bit",
                  entityConfig["bit"].caption
                ),
                nodeSize: entityConfig["bit"].size,
                nodeColor: entityConfig["bit"].color,
                nodeOrigin: target,
              },
              res.address,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.address, item.account, "Bond", res.address, parentNodes)
        );
      });
    }
  }

  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getNftRelations = async (
  address,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);
  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === address
  )[0];
  const query = gql`
      {
        nfts(where: { contract: "${address}"}) {
          contract,
          name,
          ${targetScript}
        }
      }
    `;
  let res = (await client.request(query)).nfts[0];

  originalGraphData = handlePaginationForActiveNode(
    originalGraphData,
    address,
    target,
    res[target].length
  );

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.contract)
      : [res.contract];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    if (target === "addrsHold") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.contract,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.contract, "Hold", res.contract, parentNodes)
        );
      });
    }
  }

  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getTokenRelations = async (
  address,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === address
  )[0];
  const query = gql`
      {
        tokens(where: { contract: "${address}"}) {
          contract,
          symbol,
          name,
          ${targetScript}
        }
      }
    `;
  let res = (await client.request(query)).tokens[0];

  originalGraphData = handlePaginationForActiveNode(
    originalGraphData,
    address,
    target,
    res[target].length
  );

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.contract)
      : [res.contract];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    if (target === "addrsHold") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.contract,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.contract, "Hold", res.contract, parentNodes)
        );
      });
    }
  }

  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getTwitterRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];
  const query = gql`
      {
        twitters(where: { name: "${id}" }) {
          name,
          ${targetScript}
        }
      }
    `;
  let res = (await client.request(query)).twitters[0];

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(id)
      : [id];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "avatarsInclude") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.id) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.id,
                nodeType: "avatar",
                nodeLabel: getNodeLabel(
                  item,
                  "avatar",
                  entityConfig["avatar"].caption
                ),
                nodeSize: entityConfig["avatar"].size,
                nodeColor: entityConfig["avatar"].color,
                nodeOrigin: target,
              },
              id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(id, item.id, "Bond", id, parentNodes)
        );
      });
    }
  }
  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getAvatarRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];

  const query = gql`
      {
        avatars(where: { id: "${id}" }) {
          id,
          ${targetScript},
        }
      }
    `;
  let res = (await client.request(query)).avatars[0];

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(id)
      : [id];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "includeAddrs") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, id, "Bond", id, parentNodes)
        );
      });
    } else if (target === "includeTwitters") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.name) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.name,
                nodeType: "twitter",
                nodeLabel: getNodeLabel(
                  item,
                  "twitter",
                  entityConfig["twitter"].caption
                ),
                nodeSize: entityConfig["twitter"].size,
                nodeColor: entityConfig["twitter"].color,
                nodeOrigin: target,
              },
              id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.name, id, "Bond", id, parentNodes)
        );
      });
    }
  }
  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getEventRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];

  const query = gql`
      {
        events(where: { id: "${id}" }) {
          id,
          name,
          ${targetScript}
        }
      }
    `;

  const res = (await client.request(query)).events[0];

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.id)
      : [res.id];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "addrsAttend") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.id, "Attend", res.id, parentNodes)
        );
      });
    }
  }
  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getLensRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);
  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];

  let res = [];

  const { originalItem } = triggerNode;

  if (target === "owner") {
    res = {
      handle: originalItem.handle,
      owner: [
        {
          address: originalItem.address,
        },
      ],
    };
  } else {
    const query = gql`
    {
      lens(where: { handle: "${id}" }) {
        handle,
        ${targetScript}
      }
    }
  `;

    res = (await client.request(query)).lens[0];
  }

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.handle)
      : [res.handle];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "followerAddrs") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.handle,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.handle, "Follow", res.handle, parentNodes)
        );
      });
    }

    if (target === "publishPosts") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      const nodeType = "post";
      res[target].forEach((item) => {
        const itemId = `post-${item.id}`

        if (allNodesOnGraph.indexOf(itemId) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: itemId,
                nodeType: nodeType,
                nodeLabel: getNodeLabel(
                  item,
                  nodeType,
                  entityConfig[nodeType].caption
                ),
                nodeSize: entityConfig[nodeType].size,
                nodeColor: entityConfig[nodeType].color,
                nodeOrigin: target,
              },
              res.handle,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.handle, itemId, "Post", res.handle, parentNodes)
        );
      });
    }

    if (target === "publishComments") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      const nodeType = "comment";
      res[target].forEach((item) => {
        const itemId = `comment-${item.id}`

        if (allNodesOnGraph.indexOf(itemId) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: itemId,
                nodeType: nodeType,
                nodeLabel: getNodeLabel(
                  item,
                  nodeType,
                  entityConfig[nodeType].caption
                ),
                nodeSize: entityConfig[nodeType].size,
                nodeColor: entityConfig[nodeType].color,
                nodeOrigin: target,
              },
              res.handle,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.handle, itemId, "Comment", res.handle, parentNodes)
        );
      });
    }

    if (target === "publishMirrors") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      const nodeType = "mirror";
      res[target].forEach((item) => {
        const itemId = `mirror-${item.id}`

        if (allNodesOnGraph.indexOf(itemId) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: itemId,
                nodeType: nodeType,
                nodeLabel: getNodeLabel(
                  item,
                  nodeType,
                  entityConfig[nodeType].caption
                ),
                nodeSize: entityConfig[nodeType].size,
                nodeColor: entityConfig[nodeType].color,
                nodeOrigin: target,
              },
              res.handle,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(res.handle, itemId, "Mirror", res.handle, parentNodes)
        );
      });
    }

    if (target === "owner") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.handle,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.handle, "Own", res.handle, parentNodes)
        );
      });
    }
  }
  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getSpaceRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];

  const query = gql`
      {
        spaces(where: { id: "${id}" }) {
          id,
          name,
          ${targetScript}
        }
      }
    `;

  const res = (await client.request(query)).spaces[0];

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(res.id)
      : [res.id];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "addrsVote") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              res.id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, res.id, "Vote", res.id, parentNodes)
        );
      });
    }
  }
  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

const getBitRelations = async (
  id,
  target,
  pagi,
  entityConfig,
  originalGraphData
) => {
  const targetScript = getTargetScript(target, pagi);

  const triggerNode = originalGraphData.nodes.filter(
    (item) => item.id === id
  )[0];

  const query = gql`
      {
        bits(where: { account: "${id}" }) {
          account
          ${targetScript}
        }
      }
    `;

  const res = (await client.request(query)).bits[0];

  if (res) {
    const parentNodes = triggerNode.parentNodes
      ? triggerNode.parentNodes.concat(id)
      : [id];

    const allNodesOnGraph = originalGraphData.nodes.map((item) => item.id);

    originalGraphData = handlePaginationForActiveNode(
      originalGraphData,
      id,
      target,
      res[target].length
    );

    if (target === "includeAddrs") {
      if (!checkResponseLength(res[target])) {
        return;
      }
      res[target].forEach((item) => {
        if (allNodesOnGraph.indexOf(item.address) === -1) {
          originalGraphData.nodes.push({
            ...getNode(
              {
                nodeId: item.address,
                nodeType: "address",
                nodeLabel: getNodeLabel(
                  item,
                  "address",
                  entityConfig["address"].caption
                ),
                nodeSize: entityConfig["address"].size,
                nodeColor: entityConfig["address"].color,
                nodeOrigin: target,
              },
              id,
              parentNodes
            ),
            originalItem: item,
          });
        }
        originalGraphData.edges.push(
          getEdge(item.address, id, "Bond", id, parentNodes)
        );
      });
    }
  }

  originalGraphData.edges = handleMultipleEdges(originalGraphData.edges);

  return originalGraphData;
};

export default {
  regenerate,
  getRootNode,
  getRelations,
  getAddressRelations,
  getNftRelations,
  getTokenRelations,
  getSpaceRelations,
  getTwitterRelations,
  getAvatarRelations,
  getBitRelations,
  foldNodeRelations,
  fixNode,
  unfixNode,
};
