import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconId from "assets/icons/id.png";
import { shortenAddr } from "../../../lib/tool";
import style from "../style.module.scss";

export default () => {
  const [nodeInfo] = useRecoilState(nodeInfoState)
  return (
    <div>
      <div className={style.title}>Avatar</div>

      <PropertyLine icon={IconId} title="ID" value={nodeInfo.id} valueType="avatar" />
    </div>
  );
};
