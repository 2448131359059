export const nodeSizeMapping = {
  Min: 24,
  Medium: 48,
  Max: 66,
};

export const iconSizeMapping = {
  Min: 16,
  Medium: 32,
  Max: 44,
};

export const defaultPagination = {
  limit: 20,
  offset: 0,
  expandable: true,
};

export const defaultPaginationObject = {
  addrsFollow: defaultPagination,
  followAddrs: defaultPagination,
  holdNfts: defaultPagination,
  attendEvents: defaultPagination,
  addrsHold: defaultPagination,
  addrsAttend: defaultPagination,
  includeAddrs: defaultPagination,
  includeTwitters: defaultPagination,
  avatarsInclude: defaultPagination,
  addrsVote: defaultPagination,
  voteSpaces: defaultPagination,
  bitsInclude: defaultPagination,
  holdTokens: defaultPagination,
  followerAddrs: defaultPagination,
  followLens: defaultPagination,
  lensInclude: defaultPagination,
  publishComments: defaultPagination,
  publishPosts: defaultPagination,
  publishMirrors: defaultPagination,
  owner: defaultPagination,
};
