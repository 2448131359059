import EmptyIcon from '../../assets/empty.png';

const Empty = ({
	message,
	height,
}) => {
	return (
		<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: height || '90%', alignItems: 'center'}}>
			<img src={EmptyIcon} width="48px" style={{ opacity: 0.4}} alt='' />
			<span style={{marginTop: 10, color: '#999'}}>
				{message || "No Data"}
			</span>
		</div>
	);
};

export default Empty;
