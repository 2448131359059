import React, { useState, useEffect } from "react";
import cn from "classnames";
import { useRecoilState } from "recoil";
import { userAssetsState } from "store/atom";
import style from "./style.module.scss";
import IconCopy from "assets/icons/copy.png";
import { copyToClipboard, shortenAddr } from "lib/tool";
import IconArrow from "assets/icons/arrow.svg";

const tabs = [
  {
    name: "Social Status",
    value: 0,
  },
  {
    name: "Assets and Defi",
    value: 1,
  },
  {
    name: "Governance",
    value: 2,
  },
];

export default function WalletBtnTips() {
  const [userAssets] = useRecoilState(userAssetsState);
  const [activeStakingIndex, setActiveStakingIndex] = useState([]);
  const [activeTabVal, setActiveTabVal] = useState(0);

  const toggleOpen = (toggleIndex) => {
    setActiveStakingIndex((prev) => {
      const targetedIndex = prev.indexOf(toggleIndex);
      if (targetedIndex > -1) {
        prev.splice(targetedIndex, 1);
      } else {
        prev.push(toggleIndex);
      }
      return [...prev];
    });
  };

  return (
    <>
      {Object.keys(userAssets).length > 0 && (
        <div className={style.walletTips}>
          <div className={style.walletTipsDivFist}>
            {/* <p>@Blalalala
              <img
                src={IconTwitter}
                className={style.twitter}
              />
              <span>
              </span>
            </p> */}

            {userAssets.addr && (
              <p>
                Address:<span> {shortenAddr(userAssets.addr, 6)}</span>
                <img
                  alt=""
                  src={IconCopy}
                  onClick={() => copyToClipboard(userAssets.addr)}
                  className={style.copyIcon}
                />
              </p>
            )}

            {userAssets.ens && (
              <p>
                ENS:<span> {userAssets.ens}</span>
              </p>
            )}
          </div>
          <div className={style.walletTipsTabs}>
            {tabs.map((item, index) => (
              <div key={item.value}>
                <span
                  className={cn(index === activeTabVal && style.active)}
                  onClick={() => setActiveTabVal(index)}
                >
                  {item.name}
                </span>
              </div>
            ))}
          </div>
          <div className={style.walletTipsResult}>
            {activeTabVal === 0 && (
              <div className={style.walletTipsResult_1}>
                <div>
                  <p>Following</p>
                  {userAssets.following ? (
                    userAssets.following.map((item) => (
                      <div key={item.type}>
                        <p>
                          Type: <span>{item.type}</span>
                        </p>
                        <p>
                          Count: <span>{item.count}</span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <div>None</div>
                  )}
                </div>
                <div>
                  <p>Follower</p>
                  {userAssets.follower ? (
                    userAssets.follower.map((item) => (
                      <div key={item.type}>
                        <p>
                          Type: <span>{item.type}</span>
                        </p>
                        <p>
                          Count: <span>{item.count}</span>
                        </p>
                      </div>
                    ))
                  ) : (
                    <div>None</div>
                  )}
                </div>
              </div>
            )}
            {activeTabVal === 1 && (
              <div className={style.walletTipsResult_2}>
                <div>
                  <div className={style.level_1}>IF Staking</div>
                  {userAssets.ifStaking
                    ? userAssets.ifStaking.length > 0 && (
                        <>
                          {userAssets.ifStaking.map((item, index) => (
                            <div key={index}>
                              <div
                                key={item.contract}
                                onClick={() => toggleOpen(index)}
                                className={style.level_2}
                              >
                                {shortenAddr(item.contract)}
                                <img
                                  className={cn(
                                    activeStakingIndex.indexOf(index) > -1
                                      ? style.arrowDown
                                      : style.arrowTop
                                  )}
                                  src={IconArrow}
                                />
                              </div>

                              {activeStakingIndex.indexOf(index) > -1 && (
                                <div>
                                  <div className={style.level_3}>
                                    Count: <span>{item.count}</span>
                                  </div>
                                  <div className={style.level_3}>
                                    Network: <span>{item.network}</span>
                                  </div>
                                </div>
                              )}
                            </div>
                          ))}
                        </>
                      )
                    : "None"}

                  {/* {treeData.map((item, index) => (
                    <div key={index}>
                      <div
                        onClick={() => toggleOpen([index])}
                        className={style.level_1}
                      >
                        {item.name}
                        <img
                          className={cn(
                            item.isOpen && style.arrowDown,
                            !item.isOpen && style.arrowTop
                          )}
                          src={IconArrow}
                        />
                      </div>
                      {item.isOpen &&
                        item.children.map((t, i) => (
                          <div key={i}>
                            <div
                              onClick={() => toggleOpen([index, i])}
                              className={style.level_2}
                            >
                              {h.name}: <span>{h.value}</span>
                            </div>
                            {t.isOpen &&
                              t.children.map((h, r) => (
                                <div key={r}>
                                  <div
                                    onClick={() => toggleOpen([index, i, r])}
                                    className={style.level_3}
                                  >
                                    {h.name}: <span>{h.value}</span>
                                  </div>
                                </div>
                              ))}
                          </div>
                        ))}
                    </div>
                  ))} */}
                </div>
                <div className={style.link}>
                  <p>
                    <a href={userAssets.nftLink} target="_blank">
                      NFT:{" "}
                    </a>
                    <span>{userAssets.nftNum}</span>
                  </p>
                </div>
              </div>
            )}
            {activeTabVal === 2 && (
              <div className={style.walletTipsResult_3}>
                <div className={style.link}>
                  <p>
                    <a href={userAssets.snapshotLink} target="_blank">
                      Governance:{" "}
                    </a>
                    <span>{userAssets.snapshotNum}</span>
                  </p>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
      {/* <div className={style.walletTips}>Please switch to polygon network.</div> */}
    </>
  );
}
