import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconName from "assets/icons/name.png";
import IconId from "assets/icons/id.png";

import IconLink from "assets/icons/link.png";
import style from "../style.module.scss";

export default () => {
  const [nodeInfo] = useRecoilState(nodeInfoState);

  return (
    <div>
      <div className={style.title}>SPACE</div>
      {nodeInfo.id && (
        <PropertyLine icon={IconId} title="ID" value={nodeInfo.id} />
      )}
      {nodeInfo.name && (
        <PropertyLine icon={IconName} title="Name" value={nodeInfo.name} />
      )}
      {nodeInfo.avatar && (
        <PropertyLine
          icon={IconLink}
          title="ImageURL"
          value={nodeInfo.avatar}
          valueType="image"
        />
      )}
    </div>
  );
};
