import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconName from "assets/icons/name.png";
import IconSymbol from "assets/icons/symbol.png";
import IconContract from "assets/icons/contract.png";
import style from "../style.module.scss";

export default function Nft() {
  const [nodeInfo] = useRecoilState(nodeInfoState);

  return (
    <div>
      <div className={style.title}>Token</div>
      {nodeInfo.name && (
        <PropertyLine icon={IconName} title="Name" value={nodeInfo.name} />
      )}

      {nodeInfo.symbol && (
        <PropertyLine
          icon={IconSymbol}
          title="Symbol"
          value={nodeInfo.symbol}
        />
      )}

      {nodeInfo.contract && (
        <PropertyLine
          icon={IconContract}
          title="Contract"
          value={nodeInfo.contract}
          valueType="contract"
        />
      )}
    </div>
  );
}
