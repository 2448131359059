import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconId from "assets/icons/id.png";
import style from "../style.module.scss";

export default () => {
  const [nodeInfo] = useRecoilState(nodeInfoState);

  return (
    <div>
      <div className={style.title}>BIT</div>
      {nodeInfo.id && (
        <PropertyLine icon={IconId} title="Account" value={nodeInfo.id} />
      )}
    </div>
  );
};
