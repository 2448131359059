import React from "react";
import style from "./style.module.scss";
import cn from "classnames";
import IconKnn3 from "../../assets/icons/socials/knn3.svg";
import IconKnn3Active from "../../assets/icons/socials/knn3-active.svg";
import IconGitbook from "../../assets/icons/socials/gitbook.png";
import IconGitbookActive from "../../assets/icons/socials/gitbook-active.png";
import IconTwitter from "../../assets/icons/socials/twitter.png";
import IconTwitterActive from "../../assets/icons/socials/twitter-active.png";
import IconDiscord from "../../assets/icons/socials/discord.png";
import IconDiscordActive from "../../assets/icons/socials/discord-active.png";

const socialsList = [
  {
    icon: IconKnn3,
    iconActive: IconKnn3Active,
    link: "https://www.knn3.xyz/",
  },
  {
    icon: IconGitbook,
    iconActive: IconGitbookActive,
    link: "https://docs.knn3.xyz/welcome/mashmesh",
  },
  {
    icon: IconTwitter,
    iconActive: IconTwitterActive,
    link: "https://twitter.com/Knn3Network",
  },
  {
    icon: IconDiscord,
    iconActive: IconDiscordActive,
    link: "https://discord.com/invite/6x4fUSYDJe",
  },
];

export default function Socials() {
  return (
    <div className={style.socialsList}>
      {socialsList.map((item) => (
        <a
          href={item.link}
          key={item.link}
          className={style.link}
          target="_blank"
        >
          <img src={item.icon} className={style.icon} />
          <img src={item.iconActive} className={cn(style.iconActive)} />
        </a>
      ))}
    </div>
  );
}
