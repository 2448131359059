import React, { useEffect, useState, useCallback, useRef } from "react";
import style from "./style.module.scss";
import lscache from "lscache";
import Graph from "./comp/Graph";
import { LoadingOutlined } from "@ant-design/icons";
import EntityConfigModal from "components/EntityConfigModal";
import { v4 as uuidv4 } from 'uuid';
import BeatLoader from "react-spinners/BeatLoader";
import axios from "axios";
import { useRecoilState } from "recoil";
import ReactMarkdown from "react-markdown";
import { deepClone } from "../../lib/common";
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import remarkGfm from "remark-gfm";
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism'
import copy from 'copy-to-clipboard';
import Empty from './Empty';
// import MDEditor from '@uiw/react-md-editor';
import {
  currentHighlightNodeState,
  nodeInfoState,
  nodeInfoListState,
  nodeInfoLoadingState,
  entityConfigState,
} from "store/atom";
import { filterDuplicateNodes, getNodeLabel } from "lib/tool";
import knn3 from "lib/knn3";
import { message } from "antd";
import person from '../../assets/person.svg';
import AnswerIcon from '../../assets/knn3.png';
import Copy from '../../assets/copy.svg';
import { BsCheckAll } from 'react-icons/bs'
import { PiWarningCircleFill } from 'react-icons/pi'
import IconSendmessageActive from "../../assets/icon-sendmessage-active.svg";
import IconSendmessage from "../../assets/icon-sendmessage.svg";

export default function GraphArea({ category, value }) {
  const [nodeInfo, setNodeInfo] = useRecoilState(nodeInfoState);
  const [currentHighlightNode] = useRecoilState(currentHighlightNodeState);
  const [configNodeType, setConfigNodeType] = useState(false);
  const [nodeInfoList, setNodeInfoList] = useRecoilState(nodeInfoListState);
  const [entityConfig] = useRecoilState(entityConfigState);
  const [, setNodeInfoLoading] = useRecoilState(nodeInfoLoadingState);
  const [rootNodeExpanded, setRootNodeExpanded] = useState(false);
  const [graphData, setGraphData] = useState("");
  const [renderData, setRenderData] = useState("");
  const [chatList, setChatList] = useState([]);
	const [nl_input, setNl_input] = useState("");
	const [chatCode, setChatCode] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const pageView = useRef(null);
	const [chatListLength, setChatListLength] = useState(chatList.length);
	const [isComposition, setIsComposition] = useState(false); // 是否合成输入
	const [chat_id, setChat_id] = useState(uuidv4());
  const [copyed, setCopyed] = useState(false);
	const nl_inputRef = useRef(null);
  const [toggle, setToggle] = useState(false);

  const expandRootNode = async (_nodeInfo) => {
    switch (_nodeInfo.nodeType) {
      case "address":
        onExpand(_nodeInfo, "holdNfts", true);
        break;
      case "nft":
        onExpand(_nodeInfo, "addrsHold", true);
        break;
      case "token":
        onExpand(_nodeInfo, "addrsHold", true);
        break;
      case "event":
        onExpand(_nodeInfo, "addrsAttend", true);
        break;
      case "space":
        onExpand(_nodeInfo, "addrsVote", true);
        break;
      case "bit":
        onExpand(_nodeInfo, "includeAddrs", true);
        break;
      case "twitter":
        onExpand(_nodeInfo, "avatarsInclude", true);
        break;
      case "avatar":
        onExpand(_nodeInfo, "includeTwitters", true);
        break;
      case "lens":
        onExpand(_nodeInfo, "owner", true);
        break;
    }
  };

  const addChat = useCallback(
		() => {
			const code = new Date().getTime().toString();
			setChatCode(code);
			setChatList([
				...chatList,
				{
					input: nl_input.trim(),
					chatId: chat_id.trim(),
					chatCode: code,
				}
			])
		},
		[chatList, chat_id, nl_input],
	)

  const onSend = useCallback(async() => {
		setIsLoading(true);
		try {
			const result = await axios.get("https://knn3-gateway.knn3.xyz/nl/api/chat", {
				params: { chat_id: chat_id.trim(), nl_input: chatList[chatList.length-1].input },
			});

			if (result.status === 200) {
				setChatList([
					...chatList,
					{
						...result.data,
						visualizeCode: '',
						visualizeDec: '',
						visualizeError: '',
						visualizeLoading: false
					}
				])
			}
			setIsLoading(false);
		} catch (error) {
			setChatList([...chatList, { error: error.message || "Send message error!" }]);
			setIsLoading(false);
			// toast({
			// 	title: "Send Failed!",
			// 	position: "top-right",
			// 	description: error.message || "Send message error!",
			// 	status: "error",
			// 	duration: 3000,
			// 	isClosable: true,
			// });
		}
	},
	[chatList, chat_id, setIsLoading]
	)

  useEffect(() => {
		if(nl_inputRef.current) {
      nl_inputRef.current.focus();
    }
	}, [nl_inputRef])

  useEffect(() => {
		if(pageView.current) {
			pageView.current.scrollTop = pageView.current.scrollHeight;
		}
		setChatListLength(chatList.length);
	}, [chatList]);

  useEffect(() => {
		if(chatListLength %2 ===1) onSend();
	}, [chatListLength])

  const generateRootNode = async (currentNodeInfo, originalItem) => {
    let result = await knn3.graph.getRootNode(
      currentNodeInfo,
      entityConfig,
      originalItem
    );
    if (result) {
      setGraphData(result);
    }
  };

  const onConfig = (_nodeType) => {
    setConfigNodeType(_nodeType);
  };

  const generateRelation = async (currentNodeInfo, target) => {
    let result = await knn3.graph.getRelations(
      currentNodeInfo,
      target,
      entityConfig,
      graphData
    );
    if (result) {
      setGraphData(JSON.parse(JSON.stringify(result)));
    }
  };

  const regenerateGraph = async () => {
    if (!graphData) {
      return;
    }
    let result = await knn3.graph.regenerate(
      entityConfig,
      currentHighlightNode,
      graphData
    );

    if (result) {
      setGraphData(JSON.parse(JSON.stringify(result)));
    }
  };

  const getNodeInfo = async (nodeId, nodeType, generateRoot) => {
    const cachedNodeInfo = nodeInfoList[nodeId];
    if (cachedNodeInfo) {
      setNodeInfo(cachedNodeInfo);
      return;
    }

    let rootNodeLabel = "";
    let currentNodeInfo = {};
    let res = "";
    setNodeInfoLoading(true);
    if (nodeType === "address") {
      res = (await knn3.getAddress(nodeId, true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );
      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };
      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "nft") {
      res = (await knn3.getNFT(nodeId, true))[0];

      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "token") {
      res = (await knn3.getToken(nodeId))[0];

      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "twitter") {
      res = { id: nodeId };
      rootNodeLabel = getNodeLabel(
        { id: nodeId },
        nodeType,
        entityConfig[nodeType].caption
      );
      currentNodeInfo = {
        nodeId,
        nodeType,
        id: nodeId,
      };
      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "bit") {
      res = { id: nodeId, account: nodeId };
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );
      currentNodeInfo = {
        nodeId,
        nodeType,
        id: nodeId,
      };
      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "avatar") {
      res = { id: nodeId };
      rootNodeLabel = getNodeLabel(
        { id: nodeId },
        nodeType,
        entityConfig[nodeType].caption
      );
      currentNodeInfo = {
        nodeId,
        nodeType,
        id: nodeId,
      };
      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "event") {
      res = (await knn3.getEvent(nodeId, true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "space") {
      res = (await knn3.getSpace(nodeId, true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "lens") {
      res = (await knn3.getLens(nodeId, true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "post") {
      res = (await knn3.getPost(nodeId.split("-")[1], true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "comment") {
      res = (await knn3.getComment(nodeId.split("-")[1], true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    } else if (nodeType === "mirror") {
      res = (await knn3.getMirror(nodeId.split("-")[1], true))[0];
      rootNodeLabel = getNodeLabel(
        res,
        nodeType,
        entityConfig[nodeType].caption
      );

      currentNodeInfo = {
        nodeId,
        nodeType,
        ...res,
      };

      setNodeInfo(currentNodeInfo);
    }
    setNodeInfoLoading(false);
    setNodeInfoList((prev) => {
      return {
        ...prev,
        [nodeId]: currentNodeInfo,
      };
    });

    if (generateRoot) {
      lscache.set("storageSidebarInfo", currentNodeInfo, 5);
      generateRootNode(
        {
          nodeId,
          nodeType,
          nodeLabel: rootNodeLabel,
        },
        res
      );
    }
  };

  useEffect(() => {
    // re-render root node when params change
    const storageGraph = lscache.get("storageGraph");
    const storageSidebarInfo = lscache.get("storageSidebarInfo");

    if (
      storageGraph &&
      storageSidebarInfo &&
      storageGraph.pathname === window.location.pathname
    ) {
      setGraphData(storageGraph);
      setNodeInfo(storageSidebarInfo);
      setRootNodeExpanded(true);
    } else {
      getNodeInfo(value, category, true);
    }
  }, [category, value]);

  useEffect(() => {
    regenerateGraph();
  }, [entityConfig, currentHighlightNode]);

  const iframeErrHandle = useCallback(
		(err, index) => {
			let listClone = deepClone(chatList);
			listClone[index].visualizeError = err;
		},
		[chatList],
	)

  useEffect(() => {
    if (!graphData) {
      return;
    }

    // todo, this condition is not quite accurate
    if (!rootNodeExpanded) {
      expandRootNode(graphData.nodes[0]);
      setRootNodeExpanded(true);
    }

    const formattedData = {
      pathname: window.location.pathname,
      nodes: filterDuplicateNodes(graphData.nodes),
      edges: graphData.edges,
    };

    // expires in 5 minutes
    lscache.set("storageGraph", formattedData, 5);

    setRenderData(formattedData);
  }, [graphData]);

  const visualizeHandler = useCallback(
		async (sqlOri, index) => {
			try {
				let listClone = deepClone(chatList);
				listClone[index].visualizeLoading = true;
				setChatList(listClone);
				const result = await axios.get("https://knn3-gateway.knn3.xyz/nl/api/insight", {
					params: { chat_id, sql: sqlOri }
				});
				listClone = deepClone(chatList);
				listClone[index].visualizeCode = result.data.code;
				listClone[index].visualizeDec = result.data.description;
				listClone[index].visualizeLoading = false;
				setChatList(listClone);
			} catch (error) {
				const listClone = deepClone(chatList);
				listClone[index].visualizeError = error.message;
				listClone[index].visualizeLoading = false;
				setChatList(listClone);
			}
		},
		[chatList, chat_id],
	)

  const onExpand = async (currentNodeInfo, target, muted) => {
    if (!muted) {
      message.loading({
        key: currentNodeInfo.nodeId,
        content: `Expanding`,
        duration: 0,
      });
    }

    try {
      await generateRelation(currentNodeInfo, target);
      if (!muted) {
        message.success({
          key: currentNodeInfo.nodeId,
          content: `Expanded`,
        });
      }
    } catch (err) {
      if (!muted) {
        message.info({
          key: currentNodeInfo.nodeId,
          content: err.message,
        });
      }
    }
  };

  const onFold = async (nodeId, nodeOrigin) => {
    let result = await knn3.graph.foldNodeRelations(
      nodeId,
      nodeOrigin,
      graphData
    );
    setGraphData(JSON.parse(JSON.stringify(result)));
    message.success(`Folded`);
  };

  const onFix = async (nodeId, parentId) => {
    let result = await knn3.graph.fixNode(nodeId, parentId, graphData);
    setGraphData(JSON.parse(JSON.stringify(result)));
    message.success(`Pinned`);
  };

  const onUnfix = async (nodeId, parentId) => {
    let result = await knn3.graph.unfixNode(nodeId, parentId, graphData);
    setGraphData(JSON.parse(JSON.stringify(result)));
    message.success(`Unpinned`);
  };

  const onNodeClick = async (e) => {
    const { nodeId, nodeType } = e.item._cfg.model;
    if (nodeInfo.nodeId === nodeId) {
      return;
    }
    getNodeInfo(nodeId, nodeType, false);
  };

  const onEdgeClick = async (e) => {
  };

  if (!renderData) {
    return (
      <div className={style.loading}>
        <LoadingOutlined className={style.loadingIcon} />
      </div>
    );
  }

  return (
    <div className={style.graphArea}>
      <Graph
        renderData={renderData}
        onFold={onFold}
        onFix={onFix}
        onUnfix={onUnfix}
        onExpand={onExpand}
        onConfig={onConfig}
        onEdgeClick={onEdgeClick}
        onNodeClick={onNodeClick}
      />

      {configNodeType && (
        <EntityConfigModal
          nodeType={configNodeType}
          onCancel={() => setConfigNodeType("")}
        />
      )}
      {
        !toggle ? <div onClick={() => setToggle(true)} className={style.messageBtn}></div> : <div onClick={() => setToggle(false)} className={style.packUp}></div>
      }
      {
        toggle ? 
        <div className={style.imModal}>
          <div style={{height: 14, top: 0, position: 'absolute', background: '#fff', width: '100%'}} />
          {chatList.length === 0 && (
            <div style={{ height: 'calc(100% - 80px)'}}>
              <Empty message="No chat content" />
            </div>
          )}
          <div className={style.imWarp} ref={pageView}>
            {chatList &&
                chatList.map((item, index) => {
                  return (
                    <>
                      {
                        item.chatId ? (
                          <div style={{ padding: 20}}>
                            <div style={{ display: 'flex', gap: 12, borderRadius: 5, justifyContent: "flex-end", alignItems: 'center' }}>
                              <div style={{ maxWidth: 500, background: '#EFF2F5', fontSize: 16, padding: '8px 24px', borderRadius: 6, color: '#000', wordBreak: 'break-all' }}>{item.input}</div>
                              <div style={{ width: 48, height: 48, display: 'flex', alignItems: 'center', justifyContent: 'center', background: '#EFF2F5', borderRadius: 10 }}>
                                <img src={person} alt="" />
                              </div>
                            </div>
                            {chatCode === item.chatCode && isLoading && (
                              <div style={{display: 'flex', justifyContent: 'center'}}>
                                <BeatLoader size={8} />
                              </div>
                            )}
                          </div>
                        ) : item.error ? <div style={{background: '#EFF2F5', display: 'flex', padding: 20, alignItems: 'center'}}>
                          <div style={{width: 60}}>
                            <img src={AnswerIcon} alt="" />
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', fontSize: 16, color: 'red' }}>
                            <PiWarningCircleFill style={{ color: 'red', fontSize: 20, marginRight: 6 }} />
                            error: {item.error}
                          </div>
                        </div>
                        : <div style={{background: '#EFF2F5', display: 'flex', padding: 20}}>
                            <div style={{width: 60}}>
                              <img src={AnswerIcon} alt="" />
                            </div>
                            <div style={{ width: 'calc(100% - 100px)' }}>
                              <div style={{overflowX: 'auto'}}>
                                <ReactMarkdown remarkPlugins={[remarkGfm]}>
                                  {item.content}
                                </ReactMarkdown>
                              </div>
                              <div>
                                <ReactMarkdown
                                  children={item.sql}
                                  components={{
                                    code({node, inline, className, children, ...props}) {
                                      const match = /language-(\w+)/.exec(className || '')
                                      return !inline && match ? (
                                        <div style={{ position: 'relative' }}>
                                          <SyntaxHighlighter
                                            {...props}
                                            children={String(children).replace(/\n$/, '')}
                                            style={oneDark}
                                            language={match[1]}
                                            PreTag="div"
                                          />
                                          { !copyed 
                                            ? <img onClick={() => { 
                                              copy(item.sql)
                                              setCopyed(true);
                                              setTimeout(() => {
                                                setCopyed(false);
                                              }, 3000);
                                            }} 
                                              style={{border: 'none', cursor: 'pointer', position: 'absolute', top: 10, right: 60}}
                                              src={Copy} alt=""
                                            /> 
                                            : <BsCheckAll style={{color: '#fff', position: 'absolute', top: 10, right: 60, fontSize: 24, cursor: 'pointer'}} /> 
                                          }
                                          <button onClick={() => {
                                            if(!item.visualizeCode) {
                                              visualizeHandler(item.sqlOri, index)
                                            }
                                          }}
                                            style={{cursor: 'pointer', background: '#6959EA', position: 'absolute', top: 10, right: 10, borderRadius: 4, border: 'none', padding: '2px 10px', color: '#fff'}}>
                                              Run
                                          </button>
                                        </div>
                                      ) : (
                                        <code {...props} className={className}>
                                          {children}
                                        </code>
                                      )
                                    }
                                  }}
                                />
                              </div>
                              { item.visualizeDec ? <div style={{ fontSize: 14, fontWeight: 500, paddingBottom: 10 }}>{item.visualizeDec}</div> : null}
                              { item.visualizeCode ? <iframe srcDoc={item.visualizeCode} width="100%" height="480px" onError={(err) => iframeErrHandle(err, index)} title="21" frameborder="no"></iframe> : null }
                              { item.visualizeLoading ? <div>
                                  <BeatLoader size={8} />
                                </div> : null
                              }
                              { !item.visualizeLoading && item.visualizeError ? <div style={{ color: 'red', display: 'flex', alignItems: 'center' }}>
                                <PiWarningCircleFill style={{ color: 'red', fontSize: 20, marginRight: 6 }} />
                                error: {item.visualizeError}</div> : null
                              }
                            </div>

                        </div>
                      }
                    </>
                )
                  })
                }
          </div>
          <div style={{position: 'relative'}}>
            <input
              type="text"
              autoFocus={true}
              ref={nl_inputRef}
              style={{ fontWeight: 300}}
              value={nl_input}
              onChange={(e) => {
                setNl_input(e.target.value);
              }}
              onCompositionStart={() => setIsComposition(true)}
              onCompositionEnd={() => setIsComposition(false)}
              onKeyDown={(e) => {
                if (!e.shiftKey && e.key === "Enter") {
                  e.preventDefault();
                  if (nl_input) {
                    if(!isComposition && !isLoading) {
                      addChat();
                      setNl_input('');
                    }
                  }
                }
              }}
              placeholder="Please tell me what data you want" />
              <button disabled={nl_input.length === 0}
                style={{ position: 'absolute', top: 39, right: 14, border: 'none', padding: 0, cursor: !nl_input.length ? 'no-drop' : 'pointer'}}>
                <img width="32px" src={nl_input.length ? IconSendmessageActive : IconSendmessage} alt="" />
              </button>
          </div>
        </div> : null
      }
    </div>
  );
}
