import React from "react";
import cn from "classnames";
import { useRecoilState } from "recoil";
import { entityConfigState, nodeInfoState } from "store/atom";
import { Modal } from "antd";
import style from "./style.module.scss";

export default function EntityConfigModal({ onCancel, nodeType }) {
  const [entityConfig, setEntiyConfig] = useRecoilState(entityConfigState);

  const colorOptions = [
    "#313131",
    "#3664FF",
    "#188D90",
    "#00B871",
    "#1DA1F2",
    "#3473DB",
    "#DEA600",
    "#FF3300",
    "#00D0FF",
    "#95BAAD",
    "#4600AB",
    "#AAFE2C",
  ];

  const sizeOptions = ["Min", "Medium", "Max"];

  const captionOptions = {
    address: ["ENS", "Name", "Address"],
    nft: ["Name", "Contract"],
    token: ["Name", "Contract", "Symbol"],
    event: ["Name", "ID"],
    avatar: ["ID"],
    twitter: ["Name"],
    space: ["Name", "ID"],
    bit: ["Account"],
    lens: ["Handle"],
    post: ["PubID"],
    comment: ["PubID"],
    mirror: ["PubID"],
  };

  const onColorChange = (color) => {
    setEntiyConfig((prev) => {
      return {
        ...prev,
        [nodeType]: {
          ...prev[nodeType],
          color,
        },
      };
    });
  };

  const onSizeChange = (size) => {
    setEntiyConfig((prev) => {
      return {
        ...prev,
        [nodeType]: {
          ...prev[nodeType],
          size,
        },
      };
    });
  };

  const onCaptionChange = (caption) => {
    setEntiyConfig((prev) => {
      return {
        ...prev,
        [nodeType]: {
          ...prev[nodeType],
          caption,
        },
      };
    });
  };

  return (
    <Modal onCancel={onCancel} visible={true} footer={null} wrapClassName="entiry-config-modal" width={600}>
      <div className={style.title}>{nodeType}</div>
      <div className={style.configLine}>
        <div className={style.label}>Color</div>
        <div className={style.colorList}>
          {colorOptions.map((item) => (
            <div
              key={item}
              className={cn(
                style.color,
                entityConfig[nodeType].color === item && style.active
              )}
              style={{ background: item }}
              onClick={() => onColorChange(item)}
            />
          ))}
        </div>
      </div>

      <div className={style.configLine}>
        <div className={style.label}>Size</div>
        <div className={style.tagList}>
          {sizeOptions.map((item) => (
            <div
              key={item}
              className={cn(
                style.tag,
                entityConfig[nodeType].size === item && style.active
              )}
              onClick={() => onSizeChange(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>

      <div className={style.configLine}>
        <div className={style.label}>Caption</div>
        <div className={style.tagList}>
          {captionOptions[nodeType].map((item) => (
            <div
              key={item}
              className={cn(
                style.tag,
                entityConfig[nodeType].caption === item && style.active
              )}
              onClick={() => onCaptionChange(item)}
            >
              {item}
            </div>
          ))}
        </div>
      </div>
    </Modal>
  );
}
