import React from "react";
import { useRecoilState } from "recoil";
import { nodeInfoState } from "store/atom";
import PropertyLine from "./PropertyLine";
import IconName from "assets/icons/name.png";
import IconNetwork from "assets/icons/network.png";
import IconContract from "assets/icons/contract.png";
import IconInterface from "assets/icons/interface.png";
import IconSymbol from "assets/icons/symbol.png";
import IconLink from "assets/icons/link.png";
import style from "../style.module.scss";

export default function Nft() {
  const [nodeInfo] = useRecoilState(nodeInfoState);

  return (
    <div>
      <div className={style.title}>
        NFT
        <br /> Collection
      </div>
      {nodeInfo.name && (
        <PropertyLine icon={IconName} title="Name" value={nodeInfo.name} />
      )}
      {nodeInfo.network && (
        <PropertyLine
          icon={IconNetwork}
          title="Network"
          value={nodeInfo.network}
        />
      )}
      {nodeInfo.primaryInterface && (
        <PropertyLine
          icon={IconInterface}
          title="INTERFACE"
          value={nodeInfo.primaryInterface}
        />
      )}
      {nodeInfo.symbol && (
        <PropertyLine
          icon={IconSymbol}
          title="Symbol"
          value={nodeInfo.symbol}
        />
      )}

      {nodeInfo.contract && (
        <PropertyLine
          icon={IconContract}
          title="Contract"
          value={nodeInfo.contract}
          valueType="contract"
        />
      )}

      {nodeInfo.imageUrl && (
        <PropertyLine
          icon={IconLink}
          title="ImageURL"
          value={nodeInfo.imageUrl}
          valueType="image"
        />
      )}
    </div>
  );
}
